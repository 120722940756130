@media print {
	header img.site-logo, .header img.site-logo {
	  display: block !important; 
	}
	@media print {
	  header a[href]:after {
	    content: none !important;
	  }
	}
	header a  {
		display:block !important;
	}		
}